import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl";

import { Layout } from "../components/layout";
import { SEO } from "../components/seo";
import { Timeline } from '../components/timeline';

import { queryEvents } from '../util/query';

type PageProps = {
	path: string,
};

export const IndexPage: React.FC<PageProps> = ({ path }) => {
  const intl = useIntl();
  const { locale } = intl;

  const events = queryEvents('curfew', locale);

	const title = [] as string[];
	title.push(intl.formatMessage({id: 'Timeline'}));
	title.push(intl.formatMessage({id: 'The Curfew' }));

  return (
    <Layout path={path}>
      <SEO title={title.join(' – ')} />
      <Timeline events={events} />
    </Layout>
  );
}


export default IndexPage;
